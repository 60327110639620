@use "@carbon/react" with ($use-flexbox-grid: true);

@use '@carbon/ibm-products/css/index-without-carbon';

.iFrameskeleton {
    width: 100%;
    height: 400px;
}

//@import "ibm-styles.scss";
